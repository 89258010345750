<template>
    <div id="app" class="wrapper">
        <div class="login">
            <div class="login__inner">
                <div>
                    <img src="@/assets/images/logo.png" v-bind="logoAttrs">
                </div>
                <div>
                    <input v-model="input.login_id" type="text" placeholder="アカウントID">
                </div>
                <div>
                    <input v-model="input.password" type="password" placeholder="パスワード">
                </div>
                <Loader :loader="loader" />
                <div v-if="!loader">
                    <button class="button--oval--primary" type="button" @click="handleLogIn">ログイン</button>
                </div>
            </div>
        </div>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import Loader from "@/components/Loader.vue";
    import apiUserRole from "@/mixins/apiUserRole";
    import common from "@/mixins/common";

    export default {
        "components": {
            Loader,
        },
        "mixins": [
            apiUserRole,
            common,
        ],
        data() {
            return {
                "input": {
                    "login_id": "",
                    "password": "",
                },
                "loader": false,
                "logoAttrs": {
                    "alt": "名古屋市認知症初期集中支援チーム けんさく",
                    "title": "名古屋市認知症初期集中支援チーム けんさく",
                },
            };
        },
        "methods": {
            handleLogIn() {
                if (this.input.login_id === "" || this.input.password === "") {
                    this.$_modalError("loginInputError");
                    return;
                }
                this.loader = true;
                // ログイン
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/teams/login`,
                    this.input
                ).then(response => {
                    localStorage.setItem("token", response.data.access_token);
                    localStorage.setItem("expired", response.data.expired_at);
                    localStorage.setItem("teamId", this.input.login_id);
                    this.$_fetchingDataUserRole();
                }).catch(() => {
                    this.$_modalError("loginError");
                    this.loader = false;
                });
            },
        },
    };
</script>
