"use strict";

import common from "@/mixins/common";

export default {
    "mixins": [
        common,
    ],
    "methods": {
        $_fetchingDataUserRole() {
            // ユーザ役割取得
            common.$_axios.get(
                `${common.$_configs.apiUrl}/user/role`,
                this.$_requestConfig()
            ).then(response => {
                localStorage.setItem("role", response.data.role);
                this.$router.push({ "name": "search" });
            }).catch(() => {
                this.$_modalError("userRoleFetchError");
            });
        },
    },
};
